import { cardExclusiveUrl } from "@settings";
import { PRODUCT_TYPE } from "./enums";

export const isCardExclusiveEnv = () => {
  return location.origin === cardExclusiveUrl;
};

export const isCardProductType = (productType?: PRODUCT_TYPE) => {
  if (!productType) return false;

  return [
    PRODUCT_TYPE.CARTAO_BENEFICIO,
    PRODUCT_TYPE.CARTAO_BENEFICIO_REPRESENTANTE,
    PRODUCT_TYPE.CARTAO_CONSIGNADO,
    PRODUCT_TYPE.SAQUE_COMPLEMENTAR,
    PRODUCT_TYPE.REFIN_SAQUE_CARTAO,
  ].includes(productType);
};
